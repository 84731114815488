import store from '@/store'
import constants from "@/constants";

const ADMIN = 'מנהל'
export function canEditPerson() {
  return store.getters.getRole === constants.ADMIN
}
export function canDeletePerson() {
   return store.getters.getRole === constants.ADMIN
}
export function canAddPerson() {
   return store.getters.getRole === constants.ADMIN
}
export function canAddColel() {
   return store.getters.getRole === constants.ADMIN
}
export function canDeleteTest() {
   return store.getters.getRole === constants.ADMIN
}
export function canEditTest() {
   return store.getters.getRole === constants.ADMIN
}
export function canAddTest() {
   return store.getters.getRole === constants.ADMIN
}
export function canPostAll() {
  return true
}
export function minMonthPresence() {
  return 1
}

